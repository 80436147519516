


































import { Component, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { Loader } from '../../shared/molecules/Loader'
import { RouteName } from '../routes'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
enum PaymentStatus {
  Failed,
  Pending,
  Succeed
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<PaymentReturnView>({
  name: 'PaymentReturnView',
  components: { Loader },

  created (): void {
    this.considerStatus()
  },
  mounted (): void {
    this.considerRedirection()
  }
})
export class PaymentReturnView extends Vue {
  public ableToRedirect: boolean = true
  protected status: PaymentStatus | null = null

  protected get code (): string {
    return String(this.$route.query.error ?? this.$route.query.paymentStatus ?? '')
  }

  public get backLink (): RawLocation {
    return '/'
  }

  public get icon (): string {
    return require('./assets/payment-pending.svg')
  }

  public considerRedirection (): void {
    switch (this.status) {
      case PaymentStatus.Failed:
        setTimeout(() => this.$router.push({
          name: `checkout.${RouteName.PaymentError}`,
          params: { code: this.code }
        }), 2000)
        break
      case PaymentStatus.Pending:
        setTimeout(() => {
          this.ableToRedirect = false
        }, 3500)
        break
      case PaymentStatus.Succeed:
        setTimeout(() => this.$router.push({ name: `checkout.${RouteName.ThankYou}` }), 2000)
    }
  }

  protected considerStatus (): void {
    if (typeof this.$route.query.paymentStatus !== 'undefined') {
      switch (String(this.$route.query.paymentStatus).toLowerCase()) {
        case 'confirmed':
          this.status = PaymentStatus.Succeed
          break
        case 'rejected':
          this.status = PaymentStatus.Failed
          break
        default:
          this.status = PaymentStatus.Pending
      }
    } else if (typeof this.$route.query.error !== 'undefined') {
      this.status = PaymentStatus.Failed
    } else {
      this.status = PaymentStatus.Succeed
    }
  }
}

export default PaymentReturnView
